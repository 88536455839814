<template>
  <div class="form-input-box">
    <div class="form-box">
      <table class="form-input-table">
        <tr>
          <td>
            <label class="form-label">{{label}}</label>
            <i v-if="required" class="required-txt">*</i>
          </td>
          <td @click="selectEvent">
            <p class="select-txt" :class="{'select-txt-placeholder':!checkItem,'select-txt-placeholder-disable':disable}">{{checkItem?checkItem:"请选择"}}</p>
          </td>
        </tr>
      </table>
    </div>
    <i class="select-icon"></i>
    <mt-popup v-model="popupShow" position="bottom">
      <div class="popup-box">
        <div class="popup-header" @touchmove.prevent>
          <button @click="popupShow=false;">取消</button>
          <button @click="finishEvent">完成</button>
        </div>
        <mt-picker v-if="ajaxEnd" :slots="slots" @change="onValuesChange" @touchmove.native.stop.prevent></mt-picker>
      </div>
    </mt-popup>
  </div>
</template>

<script>
import { Popup, Picker } from "mint-ui";
import { getOrgNatureJson } from "@/api/customerCenter";

export default {
  name: "x-mechanism-select",
  data () {
    return {
      popupShow: false,
      checkItemTemp: "",
      checkItem: "",
      xhrData: [],
      ajaxEnd: false,
      temp: null,
      slots: [{
        flex: 1,
        values: [],
        textAlign: "center",
        defaultIndex: 1
      }, {
        flex: 1,
        values: [],
        textAlign: "center"
      }, {
        flex: 1,
        values: [],
        textAlign: "center"
      }]
    };
  },
  props: {
    label: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    disable: {
      type: Boolean,
      default: false
    },
    bindValue: {
      type: [Number, String],
      default: null
    }
  },
  created () {
    this.getData();
  },
  methods: {
    getData () {
      getOrgNatureJson().then(xhr => {
        this.xhrData = xhr.data;
        this.xhrData.map(item => {
          this.slots[0].values.push(item.desc);
        });
        setTimeout(() => {
          this.slots[0].defaultIndex = 0;
        }, 0);
        this.ajaxEnd = true;
      });
    },
    onValuesChange (picker, values) {
      this.xhrData.map((item, index) => {
        if (item.desc == values[0]) {
          const arr = [];
          const arrs = [];
          if (index <= 0) {
            Object.keys(item.sub).forEach((key) => {
              arr.push(key);
              if (values[1] == key) {
                item.sub[key].map(v => {
                  arrs.push(v);
                });
              }
            });
          } else {
            Object.keys(item.sub).forEach((key) => {
              arr.push(item.sub[key]);
            });
          }
          picker.setSlotValues(1, arr);
          picker.setSlotValues(2, arrs.length <= 0 ? ["无"] : arrs);
          if (this.temp != values[1]) {
            this.temp = values[1];
            setTimeout(() => {
              picker.setSlotValue(2);
            }, 0);
          }
        }
      });
      this.checkItemTemp = "";
      values.map(item => {
        if (item != "无") {
          this.checkItemTemp += `${item} - `;
        }
      });
      this.checkItemTemp = this.checkItemTemp.substring(0, this.checkItemTemp.lastIndexOf(" - "));
    },
    finishEvent () {
      this.popupShow = false;
      this.checkItem = this.checkItemTemp;
      this.$emit("change", this.checkItem);
    },
    selectEvent () {
      if (!this.disable) {
        this.popupShow = !this.popupShow;
      }
    }
  },
  watch: {
    bindValue (newVal) {
      this.checkItemTemp = newVal;
      this.checkItem = newVal;
    }
  },
  components: {
    [Popup.name]: Popup,
    [Picker.name]: Picker
  }
};
</script>

<style scoped>
.form-input-box {
  display: flex;
  align-items: center;
  padding: 0.16rem 0;
  border-bottom: solid 0.01rem #f6f8fb;
}
.form-box {
  flex: 1;
}
.form-input-table {
  width: 100%;
}
.form-label {
  font-size: 0.14rem;
  color: #737885;
}
.required-txt {
  margin-left: 0.04rem;
  font-size: 0.14rem;
  color: #f75959;
}
.select-txt {
  font-size: 0.14rem;
  text-align: right;
  color: #30353d;
}
.select-txt-placeholder-disable {
  color: #737885;
}
.select-txt-placeholder {
  color: #afb2b9;
}
.select-icon {
  margin-left: 0.1rem;
  width: 0.07rem;
  height: 0.12rem;
  background: url('~@images/select_icon.png') no-repeat center/0.07rem 0.12rem;
}
.popup-box {
  width: 3.75rem;
}
.popup-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 0.01rem #eef1f5;
  height: 0.5rem;
  background-color: #ffffff;
}
.popup-header button {
  height: 100%;
  padding: 0 0.16rem;
  font-size: 0.16rem;
  color: #2b82f7;
  border: 0;
  background-color: transparent;
}
</style>
