<template>
  <div class="form-input-box">
    <div class="form-box">
      <table class="form-input-table">
        <tr>
          <td>
            <label class="form-label">{{label}}</label>
            <i v-if="required" class="required-txt">*</i>
          </td>
          <td>
            <input type="text" class="form-input" :disabled="disable" :placeholder="placeholder" :maxlength="maxlength" v-model="keyWord" @input="inputEvent">
          </td>
        </tr>
      </table>
    </div>
    <ul v-show="show" class="data-list-box">
      <li v-show="xhrData.length>0&&ajaxEnd" v-for="(item,index) in xhrData" :key="index" @click="itemEvent(item);">{{item.name}}</li>
      <li v-show="xhrData.length<=0&&ajaxEnd">查无数据</li>
    </ul>
    <slot></slot>
  </div>
</template>

<script>
import { tianyanchaSearch } from "@/api/customerCenter";

export default {
  name: "x-org-name-auto",
  data () {
    return {
      show: false,
      keyWord: null,
      ajaxEnd: false,
      timer: null,
      xhrData: []
    };
  },
  props: {
    label: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    disable: {
      type: Boolean,
      default: false
    },
    maxlength: {
      type: Number,
      default: 100
    },
    placeholder: {
      type: String,
      default: "请输入"
    },
    value: {
      type: [Number, String],
      default: null
    }
  },
  created () {
    this.docClick();
    this.keyWord = this.value;
  },
  methods: {
    docClick () {
      document.addEventListener("click", e => {
        if (!this.$el.contains(e.target)) {
          this.show = false;
        }
      });
    },
    itemEvent (item) {
      this.show = false;
      this.keyWord = item.name;
      this.$emit("change", item);
    },
    inputEvent () {
      this.$emit("input", this.keyWord);
      this.show = true;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.xhrData = [];
        this.$emit("change", null);
        this.ajaxEnd = false;
        if (this.keyWord) {
          tianyanchaSearch({
            keyWord: this.keyWord
          }).then(xhr => {
            xhr.code == 200 && xhr.data.result && (this.xhrData = xhr.data.result.items);
            this.ajaxEnd = true;
          });
        } else {
          this.show = false;
        }
      }, 300);
    }
  },
  watch: {
    value (val) {
      this.keyWord = val;
    }
  },
  components: {}
};
</script>

<style scoped>
.form-input-box {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.16rem 0;
  border-bottom: solid 0.01rem #f6f8fb;
}
.form-box {
  flex: 1;
}
.form-input-table {
  width: 100%;
}
.form-label {
  font-size: 0.14rem;
  color: #737885;
}
.form-input {
  width: 100%;
  text-align: right;
  border: 0;
  font-size: 0.14rem;
  color: #30353d;
}
.form-input::-webkit-input-placeholder {
  color: #afb2b9;
}
.required-txt {
  margin-left: 0.04rem;
  font-size: 0.14rem;
  color: #f75959;
}
.data-list-box {
  position: absolute;
  width: 100%;
  max-height: 2rem;
  top: 0.53rem;
  background-color: #ffffff;
  box-shadow: 0 0.04rem 0.08rem 0.01rem rgba(204, 216, 231, 0.5);
  border-radius: 0.08rem;
  overflow: auto;
  padding-bottom: 0.15rem;
}
.data-list-box li {
  padding: 0.15rem 0.15rem 0;
  font-size: 0.14rem;
  color: #090932;
}
</style>
