<template>
  <div class="app-box">
    <x-scroll-view :bottom="0.66">
      <x-form-box>
        <x-org-name-auto :label="orgLabel" required v-model="xhrData.orgName" @change="changeOrg"></x-org-name-auto>
        <x-form-input label="地区" placeholder="请选择" required disable v-model="cityArea">
          <i class="pos-address-icon" @click="addressEvent"></i>
        </x-form-input>
        <x-form-input label="详细地址" placeholder="" required disable v-model="xhrData.address"></x-form-input>
        <x-form-input label="社会统一信用代码" placeholder="" disable v-model="xhrData.creditCode"></x-form-input>
        <x-form-date label="成立日期" @change="establishDateChange" :defaultDate="xhrData.establishDate"></x-form-date>
        <x-form-input label="注册资金" placeholder="" v-model="xhrData.regCapital"></x-form-input>
        <div v-show="$route.params.customerType == 1000">
          <x-form-input label="营业面积" type="numberDot" :maxlength="10" v-model="xhrData.businessArea">
            <label class="from-unit">平方米</label>
          </x-form-input>
          <x-mechanism-select label="机构性质" @change="orgNatureChange" :bindValue="xhrData.orgNature"></x-mechanism-select>
          <!-- <x-form-input label="其他机构性质" v-model="xhrData.otherOrgNature"></x-form-input> -->
          <x-form-select label="国药商城是否开户" :slots="shopOpenEnum" @change="shopOpenChange" :bindValue="xhrData.shopOpen"></x-form-select>
          <x-form-select v-show="isClinic" label="门诊部" :slots="clinicEnum" @change="clinicChange" :bindValue="xhrData.clinic"></x-form-select>
        </div>
        <div v-show="$route.params.customerType == 2000">
          <x-form-input label="员工数" type="number" :maxlength="10" v-model="xhrData.staffNumber">
            <label class="from-unit">人</label>
          </x-form-input>
          <x-form-input label="年销售额" type="numberDot" :maxlength="10" v-model="xhrData.annualSales">
            <label class="from-unit">万元</label>
          </x-form-input>
          <x-form-input label="负责人姓名" v-model="xhrData.chargeName"></x-form-input>
        </div>
      </x-form-box>
    </x-scroll-view>
    <x-footer-box :type="type" :isDisabledBtn="isDisabledBtn" @saveEvent="saveClick" @delEvent="delClick"></x-footer-box>
  </div>
</template>

<script>
import formBox from "@/components/formControl/formBox";
import formInput from "@/components/formControl/formInput";
import orgNameAuto from "@/components/customerCenter/orgNameAuto";
import formSelect from "@/components/formControl/formSelect";
import formDate from "@/components/formControl/formDate";
import footerBox from "@/components/footerBox";
import scrollView from "@/components/scrollView";
import mechanismSelect from "@/components/customerCenter/mechanismSelect";
import { getCustomer, addCustomer, updateCustomer, deleteCustomer } from "@/api/customerCenter";
import { cachePage } from "@/script/mixins/cachePage";
import { ajaxBack } from "@/script/mixins/ajaxBack";
import { isDate } from "@/script/tools";
import { qqMapGeocoder } from "@/api/base";
import { Toast } from "mint-ui";

export default {
  name: "customerCenterDetailBaseInfoEdit",
  data () {
    return {
      orgLabel: this.$route.params.customerType == 1000 ? "机构名称" : "经销商名称",
      isDisabledBtn: true,
      type: this.$route.params.type,
      cityArea: null,
      isClinic: false,
      xhrData: {
        customerType: this.$route.params.customerType,
        orgName: null,
        creditCode: null,
        latitude: null,
        longitude: null,
        address: null,
        businessArea: null,
        establishDate: null,
        orgNature: null,
        otherOrgNature: null,
        staffNumber: null,
        regCapital: null,
        shopOpen: null,
        chargeName: null,
        annualSales: null,
        clinic: null
      },
      shopOpenEnum: [{
        flex: 1,
        values: [],
        textAlign: "center",
        defaultIndex: 0
      }],
      clinicEnum: [{
        flex: 1,
        values: [{
          code: 1,
          name: "是"
        }, {
          code: 0,
          name: "否"
        }],
        textAlign: "center",
        defaultIndex: 0
      }]
    };
  },
  mixins: [cachePage, ajaxBack],
  created () {
    this.type == "new" && (document.title = "基本信息");
    this.type == "edit" && this.getData();
    this.setEnum();
  },
  methods: {
    changeOrg (item) {
      if (item) {
        this.xhrData.orgName = item.name;
        this.xhrData.creditCode = item.creditCode;
        this.xhrData.regCapital = item.regCapital;
        if (isDate(item.estiblishTime.split(" ")[0])) {
          this.xhrData.establishDate = item.estiblishTime.split(" ")[0];
        }
      } else {
        this.xhrData.creditCode = null;
        this.xhrData.regCapital = null;
        this.xhrData.establishDate = null;
      }
    },
    setEnum () {
      const configJson = JSON.parse(sessionStorage.getItem("configJson")).GuoyaoShopOpenStatusEnum;
      Object.keys(configJson).forEach(key => {
        this.shopOpenEnum[0].values.push({
          code: key,
          name: configJson[key]
        });
      });
    },
    getData () {
      getCustomer({
        customerId: this.$route.params.id
      }).then(xhr => {
        this.xhrData = xhr.data;
        this.xhrData.orgNature && (this.isClinic = this.xhrData.orgNature.indexOf("公立 - 专科医院") >= 0);
        this.xhrData.latitude && this.xhrData.longitude && this.getCity();
      });
    },
    getCity () {
      qqMapGeocoder({
        latitude: this.xhrData.latitude,
        longitude: this.xhrData.longitude
      }).then(xhr => {
        this.cityArea = `${xhr.data.result.address_component.province} ${xhr.data.result.address_component.city} ${xhr.data.result.address_component.district}`;
      });
    },
    establishDateChange (item) {
      this.xhrData.establishDate = item;
    },
    clinicChange (item) {
      this.xhrData.clinic = item.code;
    },
    shopOpenChange (item) {
      this.xhrData.shopOpen = item.code;
    },
    orgNatureChange (item) {
      this.xhrData.orgNature = item;
      this.isClinic = this.xhrData.orgNature.indexOf("公立 - 专科医院") >= 0;
    },
    saveClick () {
      if (!this.isDisabledBtn) {
        if (this.xhrData.creditCode && this.xhrData.creditCode.length < 18) {
          Toast("请输入正确社会统一信用代码");
          return;
        }
        if (this.type == "new") {
          addCustomer(this.xhrData).then(xhr => {
            this.ajaxBack(xhr, "添加成功！");
          });
        } else {
          updateCustomer(this.xhrData).then(xhr => {
            this.ajaxBack(xhr, "修改成功！");
          });
        }
      }
    },
    delClick () {
      deleteCustomer({
        customerId: this.xhrData.id
      }).then(xhr => {
        this.ajaxBack(xhr, "删除成功！", -2);
      });
    },
    addressEvent () {
      let path = "/customerCenter/baseInfo/addressMap";
      this.xhrData.orgName && (path = `/customerCenter/baseInfo/addressMap/${this.xhrData.orgName}`);
      this.$router.push({
        path: path
      });
    }
  },
  watch: {
    xhrData: {
      handler (newVal) {
        this.isDisabledBtn = !newVal.orgName || !newVal.latitude || !newVal.longitude;
      },
      deep: true
    },
    $route () {
      const searchValue = this.$store.getters.getSearchValue;
      if (searchValue) {
        this.xhrData.latitude = searchValue.location.lat;
        this.xhrData.longitude = searchValue.location.lng;
        this.xhrData.address = searchValue.address;
        // this.xhrData.orgName = searchValue.title;
        this.cityArea = `${searchValue.province} ${searchValue.city} ${searchValue.district}`;
        this.$store.commit("setSearchValue", null);
      }
    }
  },
  components: {
    [scrollView.name]: scrollView,
    [formInput.name]: formInput,
    [formInput.name]: formInput,
    [formBox.name]: formBox,
    [formSelect.name]: formSelect,
    [footerBox.name]: footerBox,
    [formDate.name]: formDate,
    [orgNameAuto.name]: orgNameAuto,
    [mechanismSelect.name]: mechanismSelect
  }
};
</script>

<style scoped>
.pos-address-icon {
  margin-left: 0.1rem;
  width: 0.14rem;
  height: 0.18rem;
  background: url('~@images/pos_address_icon.png') no-repeat center/0.14rem
    0.18rem;
}
</style>
